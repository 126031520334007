import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class CenterService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  detail(id): Observable<any> {
    return this.getRequest(AppUrl.CENTER(id));
  }

  verifyOTP(data): Observable<any> {
    return this.postRequest(AppUrl.VERIFY_OTP_CENTER(), data);
  }

  getBranches(data): Observable<any> {
    return this.getRequest(AppUrl.CENTER_BRANCH(), data);
  }
}
