<div class="page-main-header">
  <div class="main-header-right">
    <div class="main-header-left text-center">
      <div class="logo-wrapper"><a href="javascript:void(0)" routerLink='/'>
        <img src="assets/images/logo/logo2.png" alt="Admission Management System" style="height: 60px"></a>
      </div>
    </div>
    <div class="mobile-sidebar">
      <div class="media-body text-right switch-sm">
        <label class="switch ml-3"><input id="sidebar-toggle" type="checkbox" checked="checked"
            (click)="collapseSidebar()">
          <app-feather-icons class="font-primary" [icon]="'align-center'"></app-feather-icons>
        </label>
      </div>
    </div>
    <div class="vertical-mobile-sidebar"><i class="fa fa-bars sidebar-bar"> </i></div>
    <div class="nav-right col pull-right right-menu">
      <ul class="nav-menus" [ngClass]="{'open': openNav}">
        <li>
          <form class="form-inline search-form" method="get">
            <div class="form-group">
              <div class="Typeahead Typeahead--twitterUsers">
                <div class="u-posRelative">
                  <input class="Typeahead-input form-control-plaintext" [class.open]="navServices.openclass" id="demo-input" type="text" name="q"
                    placeholder="Search.." [(ngModel)]="text" [ngModelOptions]="{standalone: true}"
                    (keyup)="searchTerm(text)" autocomplete="off">
                  <div class="Typeahead-menu custom-scrollbar" [class.is-open]="searchResult" *ngIf="menuItems?.length"
                    id="search-outer">
                    <div class="ProfileCard u-cf" *ngFor="let menuItem of menuItems | slice:0:8">
                      <div class="ProfileCard-avatar">
                        <i class="{{menuItem.icon}}"></i>
                      </div>
                      <div class="ProfileCard-details">
                        <div class="ProfileCard-realName">
                          <a [routerLink]="menuItem?.path" class="realname"
                            (click)="removeFix()">{{menuItem?.title}}</a>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="Typeahead-menu custom-scrollbar" [class.is-open]="searchResultEmpty">
                    <div class="tt-dataset tt-dataset-0">
                      <div class="EmptyMessage">
                        Opps!! There are no result found.
                      </div>
                    </div>
                  </div>
                  <div class="spinner-border Typeahead-spinner" role="status"><span class="sr-only">Loading...</span>
                  </div><span class="d-sm-none mobile-search" (click)="opensearch()">
                    <app-feather-icons [icon]="'search'"></app-feather-icons>
                  </span>
                </div>
                <div class="Typeahead-menu custom-scrollbar"></div>
              </div>
            </div>
          </form>
        </li>
        <!--<li class="text-dark" (click)="toggleFullScreen()">
          <app-feather-icons [icon]="'maximize'"></app-feather-icons>
        </li>-->
        <li class="onhover-dropdown">
          <img class="img-fluid img-shadow-warning" src="assets/images/dashboard/notification.png" alt="Notification" [routerLink]="['notify/home']">
          <!--<ul class="onhover-show-div-notification notification-dropdown">
            <li class="gradient-primary">
              <h5 class="f-w-700">Notifications</h5>
            </li>
            <li>
              <div class="media">
                &lt;!&ndash;<div class="notification-icons bg-success mr-3"><app-feather-icons class="mt-0" [icon]="'thumbs-up'"></app-feather-icons></div>&ndash;&gt;
                <div class="media-body" [routerLink]="['notify/home']">
                  <h6>Second Date Phase for B.Sc. (Honour)</h6>
                  <p class="mb-0">2 Days Ago</p>
                </div>
              </div>
            </li>
            <li>
              <div class="media" [routerLink]="['notify/home']">
                <div class="media-body">
                  <h6>Regarding Filling of examination forms</h6>
                  <p class="mb-0">2 Days Ago</p>
                </div>
              </div>
            </li>
            <li class="bg-light txt-dark" style="padding: 10px !important;">
              <a [routerLink]="['notify/home']">All notification</a>
            </li>
          </ul>-->
        </li>
        <li class="onhover-dropdown">
          <span class="media user-header" style="border-radius: 50%; overflow: hidden; box-shadow: 0 0 1px 1px #e0e0e0;">
            <!--<img class="img-fluid" src="assets/images/dashboard/user.png" alt="">-->
            <img class="img-fluid" [src]="profileImage" alt="Profile Image" style="width: 50px !important;">
          </span>
          <ul class="onhover-show-div profile-dropdown">
            <li class="gradient-primary">
              <h5 class="f-w-600 mb-0">{{user?.name}}</h5>
            </li>
            <li [routerLink]="['profile']"><app-feather-icons [icon]="'user'"></app-feather-icons>Profile</li>
            <li ><a (click)="logout()">
                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Logout
              </a></li>
          </ul>
        </li>
      </ul>
      <div class="d-lg-none mobile-toggle pull-right">
        <app-feather-icons [icon]="'more-horizontal'" (click)="openMobileNav()"></app-feather-icons>
      </div>
    </div>
  </div>
</div>
